<template>
  <b-row>
    <div v-if="($can( 'admin'))">
      <admin />
    </div>
  </b-row>
</template>

<script>
import admin from './components/admin.vue'

export default {
  name: 'Index',
  components: {
    admin,
  },
  data() {
    return {
      mostraMensagemGeral: false,
      tituloGeral: '',
      mensagemGeral: '',
      mostraMensagem: false,
      titulo: '',
      mensagem: '',
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<style scoped>

</style>

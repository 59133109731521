<template>
  <b-row>
    <b-col
      v-if="selectPeriodo"
      md="12"
    >
      <b-card>
        <b-row>
          <b-col md="9">
            <b-form-group>
              <h6>Selecione o Período</h6>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="{ mode: 'range', dateFormat: 'Y-m-d', altFormat: 'd/m/Y', altInput: true }"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-button
              block
              variant="warning"
              class="botao mt-2"
              @click="buscaDados()"
            >
              Escolher Período
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col md="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            <b-card-title>
              Geral
            </b-card-title>
          </b-card-title>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col
              v-for="(item, index) in dadosGerais"
              :key="index"
              md="4 "
              sm="6"
              class="mb-2 mb-md-0"
              :class="item.customClass"
            >
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="50"
                    :variant="item.color"
                  >
                    <feather-icon
                      size="20"
                      :icon="item.icon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <p class="font-weight-bolder font-medium-3 mb-0">
                    {{ item.title }}
                  </p>
                  <b-card-text class="font-small-5 mb-0">
                    {{ item.subtitle }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col
      md="12"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            Pedidos
          </b-card-title>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col
              v-for="(item, index) in dadosPedidos"
              :key="index"
              md="6"
              :class="item.customClass"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="58"
                    :variant="item.color"
                  >
                    <feather-icon
                      size="34"
                      :icon="item.icon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.title }}
                  </h4>
                  <b-card-text class="font-small-5 mb-0">
                    {{ item.subtitle }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col
      md="12"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            A Vista
          </b-card-title>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col
              v-for="(item, index) in dadosAvista"
              :key="index"
              md="4"
              sm="6"
              class="mb-2 mb-md-0"
              :class="item.customClass"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="50"
                    :variant="item.color"
                  >
                    <feather-icon
                      size="31"
                      :icon="item.icon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body
                  class="my-auto"
                >
                  <h5 class="font-weight-bolder mb-0">
                    {{ item.title }}
                  </h5>
                  <b-card-text class="font-small-5 mb-0">
                    {{ item.subtitle }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col
      md="12"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            Cartões
          </b-card-title>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col
              v-for="(item, index) in dadosCartao"
              :key="index"
              md="6"
              sm="6"
              class="mb-2 mb-md-0"
              :class="item.customClass"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="58"
                    :variant="item.color"
                  >
                    <feather-icon
                      size="31"
                      :icon="item.icon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.title }}
                  </h4>
                  <b-card-text class="font-small-5 mb-0">
                    {{ item.subtitle }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col
      md="12"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            Bancario
          </b-card-title>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col
              v-for="(item, index) in dadosBancario"
              :key="index"
              md="6"
              sm="6"
              class="mb-2 mb-md-0"
              :class="item.customClass"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="58"
                    :variant="item.color"
                  >
                    <feather-icon
                      size="32"
                      :icon="item.icon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.title }}
                  </h4>
                  <b-card-text class="font-small-5 mb-0">
                    {{ item.subtitle }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-modal
      id="Saida-Caixa"
      modal-class="modal-danger"
      centered
      title="Registre uma saida"
      hide-footer
    >
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Valor da saida ?"
            label-for="valor"
          >
            <b-form-input
              id="valor"
              v-model="valor"
              placeholder="R$ 00,00"
            />
          </b-form-group>
        </b-col>
        <b-col md="8">
          <b-form-group
            label="Descrição:"
            label-for="desc"
          >
            <b-form-input
              id="desc"
              v-model="desc"
              placeholder="Motivo desta saida"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-button
            variant="outline-warning"
            block
            @click="LimpaSaida"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button
            variant="outline-danger"
            block
            @click="SalvaSaida"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
// import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    // VueApexCharts,
    flatPickr,
  },
  data() {
    return {
      selectPeriodo: true,
      // CARDS
      dadosGerais: [],
      dadosPedidos: [],
      dadosAvista: [],
      dadosCartao: [],
      dadosBancario: [],
      dadosFiado: [],
      // RADIAL DADOS
      lastDays: [],
      chartInfo: {},
      productOrdersRadialBar: {
        // RADIAL GRAFICO ( valores em % )
        series: [100, 100, 100, 100],
        chartOptions: {
          labels: ['A vista', 'Cartao', 'Bancario', 'Carteira'],
          plotOptions: {
            radialBar: {
              size: 150,
              hollow: {
                size: '20%',
              },
              track: {
                strokeWidth: '100%',
                margin: 15,
              },
              dataLabels: {
                value: {
                  fontSize: '1rem',
                  colors: '#5e5873',
                  fontWeight: '500',
                  offsetY: 5,
                },
                total: {
                  show: true,
                  label: 'Total',
                  title: '100',
                  fontSize: '1.290rem',
                  colors: '#5e5873',
                  fontWeight: '500',

                  formatter() {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return this.title
                  },
                },
              },
            },
          },
          colors: [$themeColors.success, $themeColors.info, $themeColors.warning, $themeColors.primary],
          stroke: {
            lineCap: 'round',
          },
          chart: {
            height: 355,
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
        },
      },
      Tpedidos: '',
      icon: 'CreditCardIcon',
      PgDigital: '',
      PgCartao: '',
      PgBancario: '',
      PgAVista: '',
      TOTAL: '',
      Dinheiro_por: '',
      Cartao_por: '',
      Bancario_por: '',
      Digital_por: '',
      periodo: '',
      dropdown: true,
      rangeDate: null,
      ValorTOTAL: '',
      valor: '',
      desc: '',
    }
  },
  created() {
    this.Estatisticas()
  },
  methods: {
    async buscaDados() {
      const periodo = {
        range: this.rangeDate,
      }
      await this.$http.post('statistics/general', periodo).then(resp => {
        this.dados = resp.data
      })
      this.dadosPedidos = [
        {
          icon: 'AlertCircleIcon',
          color: 'light-danger',
          title: this.dados.PedidosPendentes,
          subtitle: 'Pedidos pendentes',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'CheckCircleIcon',
          color: 'light-info',
          title: this.dados.PedidosAtendidos,
          subtitle: 'Pedidos atendidos',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
      this.dadosAvista = [
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: this.dados.ValorDinheiro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Dinheiro',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'AtSignIcon',
          color: 'light-info',
          title: this.dados.ValorPix.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Pix',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'GridIcon',
          color: 'light-danger',
          title: this.dados.ValorQrCode.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'QrCode',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
      this.dadosCartao = [
        {
          icon: this.icon,
          color: 'light-info',
          title: this.dados.ValorCredito.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Credito',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: this.icon,
          color: 'light-success',
          title: this.dados.ValorDebito.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Debito',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
      this.dadosBancario = [
        {
          icon: 'LogInIcon',
          color: 'light-warning',
          title: this.dados.ValorTransB.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Transferencia',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'FileTextIcon',
          color: 'light-dark',
          title: this.dados.ValorBoleto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Boleto',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
      this.dadosFiado = [
        {
          icon: 'BookIcon',
          color: 'light-primary',
          title: this.dados.ValorCarteira.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Fiado',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
      this.PgDigital = this.dados.PgDigital
      this.PgCartao = this.dados.PgCartao
      this.PgBancario = this.dados.PgBancario
      this.PgAVista = this.dados.PgAVista
      this.TOTAL = this.dados.TOTAL
      this.ValorTOTAL = this.dados.ValorTOTAL
      this.lastDays = ['Last 28 Days', 'Last Month', 'Last Year']
      this.chartInfo = {
        'A vista': this.PgAVista, cartao: this.PgCartao, bancario: this.PgBancario, carteira: this.PgDigital,
      }
      this.Digital_por = (100 / this.TOTAL) * this.PgDigital
      this.Dinheiro_por = (100 / this.TOTAL) * this.PgAVista
      this.Cartao_por = (100 / this.TOTAL) * this.PgCartao
      this.Bancario_por = (100 / this.TOTAL) * this.PgBancario
      this.productOrdersRadialBar = {
        // RADIAL GRAFICO ( valores em % )
        series: [this.Dinheiro_por.toFixed(2), this.Cartao_por.toFixed(2), this.Bancario_por.toFixed(2), this.Digital_por.toFixed(2)],
        chartOptions: {
          labels: ['A vista', 'Cartao', 'Bancario', 'Carteira'],
          plotOptions: {
            radialBar: {
              size: 150,
              hollow: {
                size: '20%',
              },
              track: {
                strokeWidth: '100%',
                margin: 15,
              },
              dataLabels: {
                value: {
                  fontSize: '1rem',
                  colors: '#5e5873',
                  fontWeight: '500',
                  offsetY: 5,
                },
                total: {
                  show: true,
                  label: 'Total',
                  title: this.ValorTOTAL.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                  fontSize: '1.290rem',
                  colors: '#5e5873',
                  fontWeight: '500',

                  formatter() {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return this.title
                  },
                },
              },
            },
          },
          colors: [$themeColors.success, $themeColors.info, $themeColors.warning, $themeColors.primary],
          stroke: {
            lineCap: 'round',
          },
          chart: {
            height: 355,
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
        },
      }

      this.dadosGerais = [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: this.dados.TotalPedidos,
          subtitle: 'Pedidos no total',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: this.ValorTOTAL.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Valor total',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-info',
          title: this.dados.TotalPedidos,
          subtitle: 'Pedidos finalizados',
          customClass: 'mb-2 mb-xl-0',
        },
        // {
        //   icon: 'DollarSignIcon',
        //   color: 'light-danger',
        //   title: '200,00',
        //   subtitle: 'Saida total',
        //   customClass: 'mb-2 mb-xl-0',
        // },
      ]
      this.$loading(false)
    },
    async Estatisticas() {
      this.$loading(false)
      // get date in format Y-m-d
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const dateString = `${year}-${month}-${day}`
      const dateString30 = `${year}-${month - 1}-${day}`
      this.rangeDate = `${dateString30} to ${dateString}`
      const periodo = {
        range: this.rangeDate,
      }
      await this.$http.post('statistics/general', periodo).then(resp => {
        this.dados = resp.data
      })
      this.dadosPedidos = [
        {
          icon: 'AlertCircleIcon',
          color: 'light-danger',
          title: this.dados.PedidosPendentes,
          subtitle: 'Pedidos pendentes',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'CheckCircleIcon',
          color: 'light-info',
          title: this.dados.PedidosAtendidos,
          subtitle: 'Pedidos atendidos',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
      this.dadosAvista = [
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: this.dados.ValorDinheiro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Dinheiro',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'AtSignIcon',
          color: 'light-info',
          title: this.dados.ValorPix.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Pix',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'GridIcon',
          color: 'light-danger',
          title: this.dados.ValorQrCode.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'QrCode',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
      this.dadosCartao = [
        {
          icon: this.icon,
          color: 'light-info',
          title: this.dados.ValorCredito.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Credito',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: this.icon,
          color: 'light-success',
          title: this.dados.ValorDebito.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Debito',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
      this.dadosBancario = [
        {
          icon: 'LogInIcon',
          color: 'light-warning',
          title: this.dados.ValorTransB.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Transferencia',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'FileTextIcon',
          color: 'light-dark',
          title: this.dados.ValorBoleto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Boleto',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
      this.dadosFiado = [
        {
          icon: 'BookIcon',
          color: 'light-primary',
          title: this.dados.ValorCarteira.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Fiado',
          customClass: 'mb-2 mb-xl-0',
        },
      ]
      this.PgDigital = this.dados.PgDigital
      this.PgCartao = this.dados.PgCartao
      this.PgBancario = this.dados.PgBancario
      this.PgAVista = this.dados.PgAVista
      this.TOTAL = this.dados.TOTAL
      this.ValorTOTAL = this.dados.ValorTOTAL
      this.lastDays = ['Last 28 Days', 'Last Month', 'Last Year']
      this.chartInfo = {
        'A vista': this.PgAVista, cartao: this.PgCartao, bancario: this.PgBancario, carteira: this.PgDigital,
      }
      this.Digital_por = (100 / this.TOTAL) * this.PgDigital
      this.Dinheiro_por = (100 / this.TOTAL) * this.PgAVista
      this.Cartao_por = (100 / this.TOTAL) * this.PgCartao
      this.Bancario_por = (100 / this.TOTAL) * this.PgBancario
      this.productOrdersRadialBar = {
        // RADIAL GRAFICO ( valores em % )
        series: [this.Dinheiro_por.toFixed(2), this.Cartao_por.toFixed(2), this.Bancario_por.toFixed(2), this.Digital_por.toFixed(2)],
        chartOptions: {
          labels: ['A vista', 'Cartao', 'Bancario', 'Carteira'],
          plotOptions: {
            radialBar: {
              size: 150,
              hollow: {
                size: '20%',
              },
              track: {
                strokeWidth: '100%',
                margin: 15,
              },
              dataLabels: {
                value: {
                  fontSize: '1rem',
                  colors: '#5e5873',
                  fontWeight: '500',
                  offsetY: 5,
                },
                total: {
                  show: true,
                  label: 'Total',
                  title: this.ValorTOTAL.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                  fontSize: '1.290rem',
                  colors: '#5e5873',
                  fontWeight: '500',

                  formatter() {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return this.title
                  },
                },
              },
            },
          },
          colors: [$themeColors.success, $themeColors.info, $themeColors.warning, $themeColors.primary],
          stroke: {
            lineCap: 'round',
          },
          chart: {
            height: 355,
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
        },
      }

      this.dadosGerais = [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: this.dados.TotalPedidos,
          subtitle: 'Pedidos no total',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: this.ValorTOTAL.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          subtitle: 'Valor total',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-info',
          title: this.dados.TotalPedidos,
          subtitle: 'Pedidos finalizados',
          customClass: 'mb-2 mb-xl-0',
        },
        // {
        //   icon: 'DollarSignIcon',
        //   color: 'light-danger',
        //   title: '200,00',
        //   subtitle: 'Saida total',
        //   customClass: 'mb-2 mb-xl-0',
        // },
      ]
      this.$loading(false)
    },
    SalvaSaida() {
      const saida = {
        valorAnterior: this.ValorTOTAL,
        valorSaida: this.valor,
        descricao: this.desc,
      }
      this.$http.post('envia_saida', saida)
    },
    LimpaSaida() {
      this.valor = ''
      this.desc = ''
      this.$bvModal.hide('Saida-Caixa')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.bot-filtro{
  border-radius: 5px;
  padding-right: 25px;
  padding-left: 25px;
  background-color: #efefef; padding: 5px;
}

.bot-filtro:hover
{
  cursor: pointer;
  background-color: #cdecff;
}
</style>
